<template>
    <div :style={height:heightCalc} class="view-container">
        <component :is="loadAsyncComponent" :source="media.src"></component>
    </div>
</template>

<script>
export default {
    props: {
        media: {
            type: Object,
            required: true
        }
    },

    computed: {
        loadAsyncComponent() {
            const ext = this.media.name
                .split(".")
                .pop()
                .toLowerCase();

            switch (ext) {
                case "pdf":
                    return () => ({
                        component: import("./PDFViewer"),
                        //   loading: AwesomeSpinner,
                        //   error: SadFaceComponent,
                        timeout: 1000
                    });
            }
            return () => ({
                component: import("./ImageViewer"),
                //   loading: AwesomeSpinner,
                //   error: SadFaceComponent,
                timeout: 1000
            });
        },

        heightCalc() {
            const ext = this.media.name
                .split(".")
                .pop()
                .toLowerCase();

            if (ext == 'pdf') {

                return '600px';
            }

            return 'auto';
        }
    },

    mounted() {
        console.log("sono qui");
    }
};
</script>

<style scoped>
.view-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
    justify-content: center;
}
</style>
